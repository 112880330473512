import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as closeEdHZJv3sX8Meta } from "/vercel/path0/pages/pufflabs/close.vue?macro=true";
import { default as indexjlVCkMpzTZMeta } from "/vercel/path0/pages/pufflabs/index.vue?macro=true";
import { default as tgb8yISnmKapMeta } from "/vercel/path0/pages/pufflabs/tg.vue?macro=true";
import { default as v0BXusMSCtoWMeta } from "/vercel/path0/pages/v0.vue?macro=true";
import { default as v2HjnTk9UPyxMeta } from "/vercel/path0/pages/v2.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "pufflabs-close",
    path: "/pufflabs/close",
    component: () => import("/vercel/path0/pages/pufflabs/close.vue")
  },
  {
    name: "pufflabs",
    path: "/pufflabs",
    component: () => import("/vercel/path0/pages/pufflabs/index.vue")
  },
  {
    name: "pufflabs-tg",
    path: "/pufflabs/tg",
    component: () => import("/vercel/path0/pages/pufflabs/tg.vue")
  },
  {
    name: "v0",
    path: "/v0",
    component: () => import("/vercel/path0/pages/v0.vue")
  },
  {
    name: "v2",
    path: "/v2",
    component: () => import("/vercel/path0/pages/v2.vue")
  }
]