import { Chains, createWeb3Auth } from '@kolirt/vue-web3-auth'

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    
    const web3Auth = createWeb3Auth({
      autoInit: true,
      projectId: '483d22dd87689b5fd16332eb2ecd98fd',
      chains: [
        Chains.mainnet,
        Chains.bsc,
        Chains.polygon
      ],
      // reconnectToChain: true,
      logEnabled: true
    })

    nuxtApp.vueApp.use(web3Auth)
  }
})
