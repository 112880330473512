import revive_payload_client_1L0zZkuUwD from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.2.1__idb-keyval@6.2_htv7jjenu4jgbv74pq75lu6b4e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4E1q5KJ40W from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.2.1__idb-keyval@6.2_htv7jjenu4jgbv74pq75lu6b4e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_InBvZEmCDU from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.2.1__idb-keyval@6.2_htv7jjenu4jgbv74pq75lu6b4e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Fq9TqwVm0b from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.2.1__idb-keyval@6.2_htv7jjenu4jgbv74pq75lu6b4e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_k5M5Xs0VBG from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.2.1__idb-keyval@6.2_htv7jjenu4jgbv74pq75lu6b4e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Hx3imjsU78 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.2.1__idb-keyval@6.2_htv7jjenu4jgbv74pq75lu6b4e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0FN618ESfJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.2.1__idb-keyval@6.2_htv7jjenu4jgbv74pq75lu6b4e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_Ylw0sbLHiV from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.2.1__idb-keyval@6.2_htv7jjenu4jgbv74pq75lu6b4e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_f5ExOVuept from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._m6twsbmuwrx45h4os26irg273u/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/vercel/path0/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/vercel/path0/.nuxt/element-plus-injection.plugin.mjs";
import vue_web3_auth_KZSklyis0K from "/vercel/path0/plugins/vue-web3-auth.js";
export default [
  revive_payload_client_1L0zZkuUwD,
  unhead_4E1q5KJ40W,
  router_InBvZEmCDU,
  payload_client_Fq9TqwVm0b,
  navigation_repaint_client_k5M5Xs0VBG,
  check_outdated_build_client_Hx3imjsU78,
  chunk_reload_client_0FN618ESfJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Ylw0sbLHiV,
  sentry_client_f5ExOVuept,
  sentry_client_config_o34nk2sJbg,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  vue_web3_auth_KZSklyis0K
]